import { trapFocus } from '@shopify/theme-a11y'

const handleFirstTab = (e) => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing')
    const menuSlideout = document.querySelector('menu-slideout')
    if (!menuSlideout.classList.contains('invisible')) {
      menuSlideout.classList.add('invisible')
    }

    if (!window.location.pathname.includes('/cart')) {
      const shopifyCart = document.querySelector('shopify-cart')
      if (!shopifyCart.classList.contains('invisible')) {
        shopifyCart.classList.add('invisible')
      }
    }

    window.removeEventListener('keydown', handleFirstTab)
    window.addEventListener('mousedown', handleMouseDownOnce)
  }
}

const handleMouseDownOnce = () => {
  document.body.classList.remove('user-is-tabbing')
  const menuSlideout = document.querySelector('menu-slideout')
  if (menuSlideout.classList.contains('invisible')) {
    menuSlideout.classList.remove('invisible')
  }

  if (!window.location.pathname.includes('/cart')) {
    const shopifyCart = document.querySelector('shopify-cart')
    if (shopifyCart.classList.contains('invisible')) {
      shopifyCart.classList.remove('invisible')
    }
  }

  window.removeEventListener('mousedown', handleMouseDownOnce)
  window.addEventListener('keydown', handleFirstTab)
}

window.addEventListener('keydown', (e) => {
  if(e.keyCode === 32 && document.body.classList.contains('user-is-tabbing')) {
    e.preventDefault()
  }

  handleFirstTab(e)
})

export const handleArrowDown = (el, container) => {
  const parent = el.closest('li')
  const nextSiblingEl = parent.nextElementSibling
  if (nextSiblingEl) {
    trapFocus(container, {
      elementToFocus: nextSiblingEl.querySelector('a')
    })
  }
}

export const handleArrowUp = (el, container) => {
  const parent = el.closest('li')
  const prevSiblingEl = parent.previousElementSibling
  if (prevSiblingEl) {
    trapFocus(container, {
      elementToFocus: prevSiblingEl.querySelector('a')
    })
  }
}

const updateTitleIframes = () => {
  const handleMutation = (mutationList, observer) => {
    const iframeWebMessenger = Array.prototype.slice.call(document.querySelectorAll('iframe#web-messenger-container'))

    if (iframeWebMessenger.length) {
      iframeWebMessenger?.forEach(item => {
        item?.setAttribute('title', 'Web Messenger')
      })

      observer.disconnect();
    }
  };

  const observer = new MutationObserver(handleMutation);

  const targetNode = document.getElementsByTagName("BODY")[0];

  const config = { attributes: true, childList: true, subtree: true };

  observer.observe(targetNode, config);

  setTimeout(() => {
    const iframePixels = document.querySelector('#WebPixelsManagerSandboxContainer iframe')
    const joinAdInterestGroup = document.querySelectorAll('iframe[allow="join-ad-interest-group"]')
    const iframeBlank = document.querySelector('iframe[src="about:blank"]')

    iframeBlank?.setAttribute('role', 'none')
    iframeBlank?.setAttribute('aria-hidden', 'true')

    iframePixels?.setAttribute('title', 'WebPixels Manager Sandbox')

    joinAdInterestGroup?.forEach(item => {
      item?.setAttribute('title', 'Join ad Interest Group')

    })

  }, 5000)
}

updateTitleIframes()
